<template>
  <div>
    <el-card class="box-card">
      <div class="title"></div>
      <el-divider>
        <h2>工作经验分享</h2>
      </el-divider>
      <div class="circle-button"></div>
      <div>
        <el-form
          :model="ruleForm"
          status-icon
          :rules="rules"
          ref="ruleForm"
          label-width="60px"
          class="demo-ruleForm form-signin"
          @keyup.enter.native="submitForm('ruleForm')"
        >
          <el-form-item label="用户名" prop="username">
            <el-input
              type="text"
              placeholder="请输入账号"
              v-model="ruleForm.username"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="password">
            <el-input
              type="password"
              placeholder="请输入密码"
              v-model="ruleForm.password"
              autocomplete="off"
            ></el-input>
          </el-form-item>

          <el-button type="primary" @click="submitForm('ruleForm')"
            >登入</el-button
          >
        </el-form>
      </div>
    </el-card>

    <div class="footer">
      <div class="link">
        <a href="https://beian.miit.gov.cn/" class="ba" target="_blank">
          浙ICP备20022449号-1
        </a>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.box-card {
  width: 80%;
  max-width: 560px;
  min-width: 360px;
  margin: 0 auto;
  margin-top: 150px;
  border-radius: 65px;
  -webkit-app-region: drag;
  .title {
    height: 20px;
  }
  .circle-button {
    border-radius: 50%;
    width: 0.8rem;
    height: 0.8rem;
    background: #56ff56;
    box-shadow: 0 0 2px 2px rgba($color: #56ff56, $alpha: 0.6);
    margin-top: 15px;
    position: absolute;
    left: 49.3%;
    animation-name: light;
    animation-duration: 2700ms;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }
  @keyframes light {
    from {
      opacity: 1;
    }
    to {
      opacity: 0.3;
    }
  }

  .form-signin {
    max-width: 330px;
    margin: 40px auto 0;
    background: #fff;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    margin-top: 85px;

    .el-button {
      // display: block;
      width: 100px;
      padding-right: 0;
      padding-left: 0;
    }
  }
}

.text {
  font-size: 14px;
}
.clearfix {
  text-align: center;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
  /* -webkit-app-region: drag; */
}
.footer {
  position: absolute;
  bottom: 10px;
  width: 100%;
  text-align: center;
}
.link {
  display: block;
  width: 100%;
  text-align: center;
  margin: 0 auto;
  color: #939393;
  text-decoration: none;
  float: left;
  height: 20px;
  line-height: 20px;
}
.ba {
  // float: left;
  height: 20px;
  line-height: 20px;
  text-decoration: none;
  // margin: 0px 0px 0px 5px;
  color: #939393;
}
</style>

<script>
// import HomeVue from '../Home.vue';
export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入用户名"));
      } else {
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        callback();
      }
    };
    return {
      ruleForm: {
        username: "",
        password: "",
      },
      rules: {
        username: [{ validator: validatePass, trigger: "blur" }],
        password: [{ validator: validatePass2, trigger: "blur" }],
      },
    };
  },
  methods: {
    //   登入请求
    handleLogin() {
      const password = this.ruleForm.password;

      // B64 加密密码
      let Base64 = require("js-base64").Base64;
      const base64Password = Base64.encode(password);

      // postData.password = Base64.encode(postData.password)
      this.$api1
        .post("p1/login", {
          username: this.ruleForm.username,
          password: base64Password,
        })
        .then((res) => {
          // this.$http.post('LoginApi/login', this.ruleForm).then((res) => {
          // 处理返回数据
          const { data, code, message } = res;

          // 登入成功
          if (code === 200) {
            // 0. 保存token
            localStorage.setItem("token", data.token);
            // 0-1.保存nickname
            localStorage.setItem("nickname", data.nickname);
            // 0-2.保存username
            localStorage.setItem("username", data.username);
            // 0-3.保存job
            localStorage.setItem("job", data.job);

            // 1.跳转 Home
            this.$router.push({ path: "/" });
            // 2.提示
            this.$message.success(message);
          } else {
            // 登入不成功
            // 1.提示
            this.$message.error(message);
          }
          // window.console.log(res);
        });
    },

    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.handleLogin(); // 执行登入请求
          return;
        } else {
          window.console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>
